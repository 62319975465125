import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout/Layout'
import Footer from '../components/Layout/Footer'
import AnimalResearchPic from '../posts/projects/image/animal_research/RealWorldPic.png'
import ARControlPic from '../posts/projects/image/ar_control/ARInterface2.png'
import BoschAppMiniPic from '../posts/projects/image/bosch_appmini/newTestBoard.png'
import DIA2Pic from '../posts/projects/image/dia2/Implementation.png'
import IsolaPic from '../posts/projects/image/isola/Boarding.png'
import LaserTagPic from '../posts/projects/image/laser_tag/vest-prototype-front.jpeg'
import MiniBayPic from '../posts/projects/image/minibay/MobileApp.png'
import TapTilePic from '../posts/projects/image/taptile/DesignSketch.jpeg'
import WebAppsPic from '../posts/projects/image/web_apps/GlobalTerrorism.png'
import { useEffect, useState } from 'react'

export default function Projects({ data }) {
  const [selectedProject, setSelectedProject] = useState(null)
  const [projectCover, setProjectCover] = useState(null)

  useEffect(() => {
    if (selectedProject !== null && selectedProject.frontmatter !== null) {
      const slug = selectedProject.frontmatter.slug
      let newProjectCover = null
      switch (slug) {
        case '/isola':
          newProjectCover = IsolaPic
          break
        case '/animal-research':
          newProjectCover = AnimalResearchPic
          break
        case '/ar-control':
          newProjectCover = ARControlPic
          break
        case '/bosch-appmini':
          newProjectCover = BoschAppMiniPic
          break
        case '/dia2':
          newProjectCover = DIA2Pic
          break
        case '/laser-tag':
          newProjectCover = LaserTagPic
          break
        case '/minibay':
          newProjectCover = MiniBayPic
          break
        case '/taptile':
          newProjectCover = TapTilePic
          break
        case '/web-apps':
          newProjectCover = WebAppsPic
          break
        default:
          break
      }
      setProjectCover(newProjectCover)
    }
  }, [selectedProject])

  return (
    <Layout>
      <div className='mt-12 flex justify-center'>
        <h1 className='font-display font-medium text-xl md:text-2xl'>
          Archive for Old Student Projects
        </h1>
      </div>
      <div className='mx-6 md:mx-12 xl:mx-20 mt-6 h-2 flex justify-center items-center border-t'></div>
      <div className='mt-6 px-12 flex md:justify-center md:items-center'>
        <div className='mr-0 md:mr-20'>
          {data.allMarkdownRemark.edges.map(({ node }) => {
            return (
              <Link
                to={node.frontmatter.slug}
                key={node.id}
                onMouseEnter={() => setSelectedProject(node)}
                onMouseLeave={() => setSelectedProject(null)}
              >
                <p className='text-lg mb-8 font-light md:text-right'>
                  {node.frontmatter.title}
                </p>
              </Link>
            )
          })}
        </div>
        <div className='hidden md:block w-72'>
          {selectedProject && (
            <React.Fragment>
              <div className='w-72'>
                <img
                  src={projectCover}
                  alt={selectedProject.frontmatter.slug}
                />
              </div>
              <p className='text-sm text-slate-500 mt-4'>
                {selectedProject.excerpt}
              </p>
            </React.Fragment>
          )}
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query ProjectsQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(projects)/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            type
            slug
          }
          excerpt
        }
      }
    }
  }
`
